import React from "react";
import SEO from "../components/seo";
import BaseLayout from "../components/BaseLayout";
import { $ThankYou } from "../styles/SC_ThankYou";
import { ReactTypeformEmbed } from "react-typeform-embed";

class PRE extends React.Component {
  render() {
    return <ReactTypeformEmbed url="https://form.typeform.com/to/vqFicjtR" />;
  }
}

export default PRE;
